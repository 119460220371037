<template>
  <MultiSelect
    v-model="content"
    @change="eventHandler"
    :options="groupedCities"
    optionLabel="name"
    :optionValue="optionValue"
    optionGroupChildren="items"
    optionGroupLabel="label"
    placeholder="Выберите регионы"
    :class="pickerClass"
    :disabled="disabled"
  >
    <template #optiongroup="slotProps">
      <div class="p-d-flex p-ai-center country-item">
        <img
          src="@/assets/layout/flags/flag_placeholder.png"
          :class="'flag flag-' + slotProps.option.code.toLowerCase()"
          class="p-mr-2"
          width="44"
        />
        <div>{{ slotProps.option.label }}</div>
      </div>
    </template>
  </MultiSelect>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RegionPicker",
  props: {
    value: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pickerClass: {
      type: String,
      default: "",
    },
    optionValue: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      content: this.value,
      groupedCities: [
        {
          label: "Россия",
          code: "RU",
          items: [],
        },
        {
          label: "Казахстан",
          code: "KZ",
          items: [],
        },
      ],
    };
  },

  async mounted() {
    if (!this.regions.length) {
      await this.$store.dispatch("data/loadRegions");
    }

    this.regions.forEach((region) => {
      //TODO Актуализировать под несколько стран
      if (region.country.name === "РОССИЯ") {
        this.groupedCities[0].items.push({
          name: region.name,
          uid: region.uid,
        });
      } else {
        this.groupedCities[1].items.push({
          name: region.name,
          uid: region.uid,
        });
      }
    });
  },

  methods: {
    eventHandler(event) {
      this.$emit("input", event);
    },
  },

  computed: {
    ...mapGetters({
      regions: "data/regions",
    }),
  },
};
</script>

<style scoped></style>
