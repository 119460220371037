<template>
  <h5>Менеджеры по продажам</h5>
  <div class="p-mb-3" v-if="editMode">
    <Dropdown
      id="allSalesManagers"
      v-model="newSalesManager"
      :options="allSalesManagers"
      optionLabel="textForDropdown"
      placeholder="Выберите нового менеджера по продажам"
      :filter="true"
      emptyFilterMessage="Совпадений не найдено"
      @change="changeManagerDialog()"
    >
    </Dropdown>
  </div>
  <DataTable
    :value="salesManagers"
    :paginator="true"
    :rows="10"
    dataKey="id"
    :rowHover="true"
    :autoLayout="true"
    :loading="isLoading"
  >
    <template #empty>Список менеджеров по продажам пуст</template>
    <template #loading>Загрузка...</template>
    <Column field="lastname" header="Фамилия" :sortable="true">
      <template #body="slotProps">{{ slotProps.data.lastname }}</template>
    </Column>
    <Column field="firstname" header="Имя" :sortable="true">
      <template #body="slotProps">{{ slotProps.data.firstname }}</template>
    </Column>
    <Column field="email" header="e-mail" :sortable="true">
      <template #body="slotProps"
        ><a :href="'mailto:' + slotProps.data.email">{{
          slotProps.data.email
        }}</a></template
      >
    </Column>
    <Column field="phone" header="Телефон" :sortable="true">
      <template #body="slotProps"
        ><a :href="'tel:' + slotProps.data.phone">{{
          slotProps.data.phone
        }}</a></template
      >
    </Column>
    <Column>
      <template #body="slotProps">
        <Button
          class="p-button-warning p-mr-3"
          icon="pi pi-pencil"
          @click="enableEditMode(slotProps.data)"
          v-if="permissions['edit_client']"
        />
      </template>
    </Column>
  </DataTable>
  <ConfirmDialog group="salesManagerDialog"></ConfirmDialog>
</template>

<script>
import { environment } from "@/config";

export default {
  name: "SalesManagersList",
  props: ["company"],
  data() {
    return {
      editMode: false,
      editingManager: null,
      newSalesManager: null,
      allSalesManagers: null,
      salesManagers: null,
      isLoading: false,
    };
  },
  created() {
    this.loadManagers();
    this.loadAllManagers();
  },
  computed: {
    permissions() {
      return this.$store.getters.getPermissions;
    },
  },
  methods: {
    enableEditMode(manager) {
      this.editingManager = manager;
      this.editMode = true;
    },
    changeManagerDialog() {
      this.$confirm.require({
        message: `Вы действительно хотите сменить на ${this.editingManager.firstname} ${this.editingManager.lastname} на ${this.newSalesManager.firstname} ${this.newSalesManager.lastname}`,
        group: "salesManagerDialog",
        header: "Внимание!",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Да",
        rejectLabel: "Нет",
        accept: () => {
          this.changeManager(this.newSalesManager);
        },
      });
    },
    async changeManager(manager) {
      this.isLoading = true;
      let companyForChange = null;
      this.editingManager.companies = this.editingManager.companies.filter(
        (company) => {
          if (company.uid === this.company.uid) {
            companyForChange = company;
          }
          return company.uid !== this.company.uid;
        }
      );
      manager.companies.push(companyForChange);
      try {
        await fetch(`${environment.apiUrl}/users/${this.editingManager.id}`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.editingManager),
        });

        await fetch(`${environment.apiUrl}/users/${manager.id}`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(manager),
        });
        await this.loadManagers();
        this.$toast.add({
          severity: "success",
          summary: "",
          detail: "Менеджер изменен!",
          life: 6000,
        });
        this.editMode = false;
        this.newSalesManager = null;
      } catch (err) {
        console.log(err);
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при смене менеджера",
          detail: err,
          life: 6000,
        });
      }
      this.isLoading = false;
    },
    async loadAllManagers() {
      this.isLoading = true;
      try {
        const response = await fetch(`${environment.apiUrl}/sales_managers`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
        });
        this.allSalesManagers = await response.json();
        this.allSalesManagers = this.allSalesManagers.map((manager) => {
          return {
            ...manager,
            textForDropdown: `${manager.lastname} ${manager.firstname}
            ${manager.email ? manager.email : ""} ${manager.phone}`,
          };
        });
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    },
    async loadManagers() {
      if (!this.company) return;
      this.isLoading = true;
      try {
        const response = await fetch(
          `${environment.apiUrl}/users?companyUid=${this.company.uid}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.salesManagers = await response.json();
        this.salesManagers = this.salesManagers.filter((e) => {
          return e.role === "SALES_MANAGER";
        });
      } catch (err) {
        const error =
          err.message ||
          "Не получилось загрузить список менеджеров по продажам";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при загрузке списка менеджеров по продажам!",
          detail: error,
          life: 6000,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>
