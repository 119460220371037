export const CONTRACT_STATUS = {
  ORIGINAL: "original",
  COPY: "copy",
  NOT: "not",
};

export const contractStatusColorClass = (status) => {
  switch (status) {
    case CONTRACT_STATUS.ORIGINAL:
      return "contract_green";
    case CONTRACT_STATUS.COPY:
      return "contract_grey";
    case CONTRACT_STATUS.NOT:
      return "contract_red";
    default:
      return undefined;
  }
};
