<template>
  <h3>Договор</h3>
  <div
    v-for="(contract, index) in contracts"
    :key="index"
    class="p-field p-fluid"
  >
    <label>Наименование договора</label>
    <div class="p-d-flex p-align-center">
      <ContractStatus :status="contract.status" class="p-mr-2" />
      <InputText v-model="contract.name" disabled />
    </div>
  </div>
</template>

<script>
import ContractStatus from "@/views/operator/components/ContractStatus.vue";

export default {
  name: "ContractsList",
  components: { ContractStatus },
  props: ["contracts"],
};
</script>

<style scoped></style>
