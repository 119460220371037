<template>
  <div class="p-grid">
    <div class="p-col-12 p-md-4">
      <div class="card p-fluid">
        <h3>Информация</h3>
        <div class="card p-fluid">
          <form @submit.prevent="submitForm">
            <div class="p-field" v-if="permissions['see_nonofficial_name']">
              <label for="name">Название</label>
              <InputText
                id="name"
                v-model.trim="name.val"
                :required="true"
                @blur="clearValidity('name')"
                :class="[
                  !name.isValid ? 'p-invalid' : '',
                  isUnverifiedDebtor ? 'unverifiedDebtor' : '',
                ]"
                disabled
              />
            </div>
            <div class="p-field">
              <label
                v-if="permissions['see_nonofficial_name']"
                for="officialName"
                >Официальное название</label
              >
              <label v-else for="officialName">Название</label>
              <InputText
                id="officialName"
                v-model.trim="officialName.val"
                :required="true"
                @blur="clearValidity('officialName')"
                :class="!officialName.isValid ? 'p-invalid' : ''"
                disabled
              />
            </div>
            <div class="p-field">
              <label for="regionIds">Регион</label>
              <!--              <MultiSelect-->
              <!--                id="regionIds"-->
              <!--                v-model="regionIds.val"-->
              <!--                :options="regionList"-->
              <!--                :required="true"-->
              <!--                placeholder="Выберите регионы"-->
              <!--                optionLabel="name"-->
              <!--                optionValue="uid"-->
              <!--                :class="!regionIds.isValid ? 'p-invalid' : ''"-->
              <!--                :disabled="!permissions['edit_client']"-->
              <!--              />-->
              <RegionPicker
                v-model="regionIds.val"
                :picker-class="!regionIds.isValid ? 'p-invalid' : ''"
                :disabled="!permissions['edit_client']"
                option-value="uid"
              />
            </div>
            <div class="p-field">
              <label for="legalAddress">Юридический адрес</label>
              <Textarea
                id="legalAddress"
                v-model.trim="legalAddress.val"
                :required="true"
                @blur="clearValidity('legalAddress')"
                :class="!legalAddress.isValid ? 'p-invalid' : ''"
                rows="3"
                disabled
              />
            </div>
            <div class="p-field">
              <label for="actualAddress">Фактический адрес</label>
              <Textarea
                id="actualAddress"
                v-model.trim="actualAddress.val"
                :required="true"
                @blur="clearValidity('actualAddress')"
                :class="!actualAddress.isValid ? 'p-invalid' : ''"
                rows="3"
                disabled
              />
            </div>
            <div class="p-field">
              <label for="district">Район</label>
              <InputText
                id="district"
                v-model.trim="district.val"
                :required="true"
                @blur="clearValidity('district')"
                :class="!district.isValid ? 'p-invalid' : ''"
                disabled
              />
            </div>
            <div class="p-field">
              <label for="inn">ИНН</label>
              <InputText
                id="inn"
                v-model.trim="inn.val"
                :required="true"
                @blur="clearValidity('inn')"
                :class="!inn.isValid ? 'p-invalid' : ''"
                disabled
              />
            </div>
            <div class="p-field">
              <label for="kpp">КПП</label>
              <InputText
                id="kpp"
                v-model.trim="kpp.val"
                :required="true"
                @blur="clearValidity('kpp')"
                :class="!kpp.isValid ? 'p-invalid' : ''"
                disabled
              />
            </div>
            <div class="p-field">
              <label for="email">E-mail</label>
              <InputText
                type="email"
                id="email"
                @blur="clearValidity('email')"
                :class="!email.isValid ? 'p-invalid' : ''"
                v-model.trim="email.val"
                :required="true"
                disabled
              />
            </div>
            <div class="p-field">
              <label for="phone">Контактный телефон</label>
              <InputMask
                mask="+7-999-999-99-99"
                id="phone"
                v-model.trim="phone.val"
                :required="true"
                @blur="clearValidity('phone')"
                :class="!phone.isValid ? 'p-invalid' : ''"
                :autoClear="false"
                disabled
              />
            </div>
            <div class="p-field">
              <label for="shir">Широта</label>
              <InputText
                id="shir"
                v-model.trim="shir.val"
                :required="true"
                @blur="clearValidity('shir')"
                :class="!shir.isValid ? 'p-invalid' : ''"
                disabled
              />
            </div>
            <div class="p-field">
              <label for="long">Долгота</label>
              <InputText
                id="long"
                v-model.trim="long.val"
                :required="true"
                @blur="clearValidity('long')"
                :class="!long.isValid ? 'p-invalid' : ''"
                disabled
              />
            </div>
            <div class="p-field">
              <GetDebtButton v-if="permissions['get_debt']" :client-uid="uid" />
            </div>

            <Button
              v-if="permissions['edit_client']"
              label="Обновить клиента"
              @click="submitForm"
              class="p-mt-3"
              :disabled="isLoading"
            />
          </form>
        </div>
      </div>
      <div class="card p-fliud">
        <ContractsList :contracts="contracts" />
      </div>
    </div>
    <div class="p-col-12 p-md-8">
      <div class="card p-fluid">
        <technic-list
          :company="this.company"
          :key="company?.uid"
        ></technic-list>
      </div>
      <div class="card p-fluid">
        <employe-list
          :company="this.company"
          :key="company?.uid"
        ></employe-list>
      </div>
      <div class="card p-fluid">
        <sales-managers-list :company="this.company" :key="company?.uid">
        </sales-managers-list>
      </div>
      <div align="center">
        <Button
          @click="returnToCreateTask"
          v-if="isFromCreateTask"
          label="Вернуться к заявке"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TechnicList from "@/views/operator/TechnicList";
import { environment } from "@/config";
import EmployeList from "@/views/operator/EmployeList";
import SalesManagersList from "@/views/operator/SalesManagersList";
import { mapGetters } from "vuex";
import RegionPicker from "@/components/RegionPicker";
import GetDebtButton from "@/components/GetDebtButton";
import ContractsList from "@/views/operator/ContractsList.vue";

export default {
  name: "ClientDetail",
  components: {
    ContractsList,
    GetDebtButton,
    RegionPicker,
    SalesManagersList,
    EmployeList,
    TechnicList,
  },

  created() {
    this.loadClient(this.$route.params.id);
    this.isFromCreateTask = this.$route.params.isFromCreateTask;
  },
  data() {
    return {
      uid: null,
      isFromCreateTask: false,
      company: null,
      isUnverifiedDebtor: false,
      contracts: [],
      name: {
        val: "",
        isValid: true,
      },
      officialName: {
        val: "",
        isValid: true,
      },
      regionIds: {
        val: [],
        isValid: true,
      },
      email: {
        val: "",
        isValid: true,
      },
      phone: {
        val: "",
        isValid: true,
      },
      actualAddress: {
        val: "",
        isValid: true,
      },
      district: {
        val: "",
        isValid: true,
      },
      legalAddress: {
        val: "",
        isValid: true,
      },
      shir: {
        val: "",
        isValid: true,
      },
      long: {
        val: "",
        isValid: true,
      },
      inn: {
        val: "",
        isValid: true,
      },
      kpp: {
        val: "",
        isValid: true,
      },
      isLoading: false,
      formIsValid: false,
    };
  },
  computed: {
    ...mapGetters({
      permissions: "getPermissions",
      roleList: "getRoleList",
      countryList: "data/countries",
      regionList: "data/regions",
    }),
  },
  methods: {
    returnToCreateTask() {
      this.$router.push({ name: "CreateTask" });
    },
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      this.formIsValid = true;
      this.name.isValid = true;
      this.officialName.isValid = true;
      this.regionIds.isValid = true;
      this.email.isValid = true;
      this.phone.isValid = true;
      this.actualAddress.isValid = true;
      this.district.isValid = true;
      this.legalAddress.isValid = true;
      this.shir.isValid = true;
      this.long.isValid = true;
      this.inn.isValid = true;
      this.kpp.isValid = true;
      if (!this.name.val) {
        this.name.isValid = false;
        this.formIsValid = false;
      }
      if (!this.officialName.val) {
        this.officialName.isValid = false;
        this.formIsValid = false;
      }
      if (!this.regionIds.val.length) {
        this.regionIds.isValid = false;
        this.formIsValid = false;
      }
      if (!this.actualAddress.val) {
        this.actualAddress.isValid = false;
        this.formIsValid = false;
      }
      if (!this.district.val) {
        this.district.isValid = false;
        this.formIsValid = false;
      }
      if (!this.legalAddress.val) {
        this.legalAddress.isValid = false;
        this.formIsValid = false;
      }
      if (!this.inn.val) {
        this.inn.isValid = false;
        this.formIsValid = false;
      }
    },
    async submitForm() {
      this.validateForm();

      if (!this.formIsValid) {
        return;
      }

      this.isLoading = true;

      const actionPayload = {
        uid: this.uid,
        name: this.name.val,
        officialName: this.officialName.val,
        email: this.email.val,
        phone: this.phone.val,
        regionIds: this.regionIds.val,
        actualAddress: this.actualAddress.val,
        district: this.district.val,
        legalAddress: this.legalAddress.val,
        latitude: this.shir.val,
        longitude: this.long.val,
        inn: this.inn.val,
        kpp: this.kpp.val,
      };

      try {
        await this.$store.dispatch("myprofiles/updateClient", actionPayload);
        // await this.$router.replace("/");
        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Клиент ${actionPayload.name} обновлён!`,
          life: 6000,
        });
      } catch (err) {
        const error = err.message || "Не получилось обновить клиента";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при обновлении клиента!",
          detail: error,
          life: 6000,
        });
      }

      this.isLoading = false;
    },
    async loadClient(id) {
      this.isLoading = true;
      try {
        const response = await fetch(`${environment.apiUrl}/companies/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
        });
        const responseData = await response.json();

        this.email.val = responseData.email;
        this.name.val = responseData.name;
        this.officialName.val = responseData?.officialName;
        this.phone.val = responseData.phone;
        this.actualAddress.val = responseData.actualAddress;
        this.district.val = responseData.district;
        this.regionIds.val = responseData.regionIds;
        this.legalAddress.val = responseData.legalAddress;
        this.shir.val = responseData.latitude;
        this.long.val = responseData.longitude;
        this.inn.val = responseData.inn;
        this.kpp.val = responseData.kpp;

        this.uid = responseData.uid;
        this.company = responseData;

        this.isUnverifiedDebtor = responseData.debtor && !responseData.verified;

        this.contracts = responseData.contract;
      } catch (err) {
        const error = err.message || "Не получилось загрузить клиента";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при загрузке клиента!",
          detail: error,
          life: 6000,
        });
      }

      this.isLoading = false;
    },
  },
};
</script>
<style>
.unverifiedDebtor {
  background-color: #ffcccc;
}
</style>
