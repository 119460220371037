<template>
  <div class="p-d-flex p-flex-row p-align-center">
    <Button
      v-if="!mini"
      class="p-mr-4"
      label="Запросить задолженность"
      style="width: auto"
      @click="getDebt"
      :disabled="!clientUid || disabled || isLoading"
    />
    <i @click="getDebt" v-else class="pi pi-question-circle debt-button-mini" />
    <div>{{ debtText }}</div>
  </div>
</template>

<script>
import { environment } from "@/config";

export default {
  name: "GetDebtButton",
  props: {
    clientUid: String,
    disabled: Boolean,
    mini: Boolean,
  },
  data() {
    return {
      debtText: "",
      isLoading: false,
    };
  },
  methods: {
    async getDebt() {
      this.isLoading = true;
      this.debtText = "Ожидаем ответа от сервера...";

      try {
        let response = await fetch(
          `${environment.apiUrl}/companies/${this.clientUid}/debt`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        );

        response = await response.json();

        this.debtText = response.text;
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.debt-button-mini {
  cursor: pointer;
  padding: 0 6px 3px 0;
}
</style>
