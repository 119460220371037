<template>
  <div
    :class="contractStatusColorClass(status)"
    style="border-radius: 50%; width: 0.5rem; height: 0.5rem"
  />
</template>

<script>
import { contractStatusColorClass } from "@/models/contractStatus";
export default {
  name: "ContractStatus",
  methods: { contractStatusColorClass },
  props: ["status"],
};
</script>
